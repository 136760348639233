<template>
  <div>
    <AdminHome v-if="user.role == 'admin'" />
    <EmployeeHome v-if="user.role == 'employee'" />
  </div>
</template>

<script>
import AdminHome from "./Home.vue";
import EmployeeHome from "./EmployeeHome.vue";
export default {
  data() {
    return {
      user: {},
    };
  },
  components: {
    AdminHome,
    EmployeeHome,
  },
  mounted() {
    let localUser = localStorage.getItem("user");
    this.user = JSON.parse(localUser);
  },
};
</script>

<style></style>
